import React from 'react'
import { Link } from 'gatsby'
import './layout.css'

const Layout = ({ children }) => (
  <main>
    <h1><Link to='/'>Julian Hartnett</Link></h1>
    <nav>
      <ul>
        <li><Link to='/'>Home</Link></li>
        <li><a href='/resume.pdf'>Resume</a></li>
      </ul>
    </nav>
    <section>
      {children}
    </section>
    <footer>
      <ul>
        <li><a href='mailto:hartnett.julian@gmail.com'>Email</a></li>
        <li><a href='https://twitter.com/julianhartnett'>Twitter</a></li>
        <li><a href='https://www.linkedin.com/in/julian-hartnett-041854169'>LinkedIn</a></li>
      </ul>
      <small>© 2020 by Julian Hartnett</small>
    </footer>
  </main>
)

export default Layout
