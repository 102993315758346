import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'

const Candidate = () => (
  <Layout>
    <h1>Candidate Form</h1>
    <article>
      <p>Pearson’s sales team was inundated with marketing leads and valuable time was wasted simply sorting through an unfiltered list of phone numbers in order to discover the best candidates for the educational program being offered.</p>
      <img src='/candidate-intro.png'/>
      <p>I helped create an interactive lead form to automatically identify and prioritize the highest quality candidates.</p>
      <p>This comprehensive form helped the user understand whether they were a good fit for the program we offered while simultaneously educating our sales teams about the client’s specific needs. I created a branching logic system which streamlined the users flow through the form  and sorted them based on their answers to various relevant questions.</p>
      <img src='/candidate-name.png'/>
      <p>The design was created to encourage user retention and avoid answer-fatigue (as this was a much longer lead application than was typical for our program at the time) featuring inclusions such as a progress bar and animations throughout to increase delight and engagement.</p>
      <img src='/candidate-education.png'/>
      <p>The form was a success and was eventually adopted as the company’s main filtration system for new marketing leads. It had a 50% completion rate and became the company’s highest converting lead source.</p>
      <p><a href='https://pearsonaccelerated.com/students/candidate' target='_blank'>View live site</a></p>
    </article>
  </Layout>
)

export default Candidate
